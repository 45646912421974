<template>
  <div class="subscribe-container">
    <div class="subscribe-title-box">
      <div>我的关注</div>
      <div class="title-right">
        <img
          src="@/assets/img/user/settings.png"
          width="100%"
          height="100%"
          @dragstart.prevent
          class="img-setting"
        />

        <span class="subscribe" @click="handleSet">关注设置</span>
      </div>
    </div>
    <div class="divider"></div>
    <div class="subscribe-content">
      <div v-if="total == 0" style="margin-top: 150px">
        <empty name="暂无数据"></empty>
      </div>
      <div class="subscribe-box" v-show="total != 0">
        <div
          class="subscribe-box-item"
          v-for="(item, index) in tableData"
          :key="index"
          @click="handleDetail(item)"
        >
          <div class="subscribe-left">
            <img
              class="item-icon"
              v-if="item.logoUrl"
              @dragstart.prevent
              :src="item.logoUrl"
              alt=""
            />
            <div class="img-left-logo" v-else style="background-color: #6daae2">
              <div class="logo-img">{{ item.logoImg }}</div>
            </div>
            <div class="subscribe-info">
              <el-tooltip
                effect="light"
                :content="item.clientName"
                placement="bottom-start"
              >
                <div class="info-top">
                  <span>{{ item.clientName }}</span>
                </div>
              </el-tooltip>
              <div class="info-bottom">
                <div class="bottom-left" @click.stop="handleDetail(item, 1)">
                  招标动态({{ item.tenderCount || 0 }})
                </div>
                <div class="bottom-right" @click.stop="handleDetail(item, 2)">
                  中标动态({{ item.bidWinnerCount || 0 }})
                </div>
              </div>
            </div>
          </div>
          <div class="subscribe-item-option" @click.stop="delSubscribe(item)">
            <img
              @dragstart.prevent
              src="@/assets/img/user/quxiaodingyue.png"
              alt=""
              width="100%"
              height="100%"
              class="item-icon"
            />
            <span class="item-txt">取消关注</span>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        >
        </pagination>
      </div>
      <!-- 弹窗dialog -->
      <div class="visible-box">
        <Modal
          :visible.sync="visible"
          @reset="resetFields"
          class="dialog-style"
        >
          <div
            slot="title"
            style="padding-left: 20px; border-left: 4px solid #fff"
          >
            关注设置
          </div>
          <el-form
            :model="dataPO"
            :rules="rules"
            ref="form"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-row>
              <!-- <el-col :span="24">
              <el-form-item label="客户名称" prop="name" class="title-item">
                <el-input
                  v-model.trim="dataPO.name"
                  placeholder="请输入客户名称"
                ></el-input>
              </el-form-item>
            </el-col> -->
              <el-col :span="24">
                <el-form-item
                  label="关注内容"
                  prop="isAllInfo"
                  class="time-item"
                >
                  <el-checkbox
                    v-model="dataPO.isAllInfo"
                    @change="handleCheckAllChange"
                    :label="'全选'"
                  ></el-checkbox>
                  <el-checkbox
                    v-for="item in contentList"
                    v-model="item.select"
                    :key="item.value"
                    :label="item.label"
                    @change="handleCheckContent(item)"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
              <el-form-item label="推送时间" prop="isAllDay" class="time-item">
                <el-radio-group
                  v-model="dataPO.isAllDay"
                  @change="handleChangeSendTime"
                >
                  <el-radio
                    v-for="item in timeList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
                <span style="margin-left: 10px" v-show="timeFlag">
                  <el-time-picker
                    is-range
                    v-model="dateRange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                  >
                  </el-time-picker>
                </span>
              </el-form-item>
            </el-col> -->
              <el-col>
                <div class="divider"></div>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="通知路径"
                  prop="noticeWay"
                  class="info-item"
                >
                  <div class="info-box">
                    <div class="info-top">
                      <span class="info-title">站内消息</span>
                      <span class="info-content"
                        >通知网页端/APP端系统消息通知查看</span
                      >
                      <el-switch v-model="dataPO.info" disabled> </el-switch>
                    </div>
                    <!-- <div class="info-bottom">
                    <span class="info-title">微信服务号</span>
                    <span class="info-content"
                      ><span class="wechat">绑定微信</span>
                      后可通过订阅消息查看</span
                    >
                    <el-switch v-model="dataPO.wechat"> </el-switch>
                  </div> -->
                  </div>
                </el-form-item>
              </el-col>

              <el-col>
                <el-form-item class="btn-item">
                  <el-button @click="cancel()">取消</el-button>
                  <el-button type="primary" @click="submitForm('form')"
                    >确认</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
// import { validateTax } from "@/utils/validate.js";
export default {
  components: {
    Pagination,
    Empty,
    Modal
  },
  data() {
    return {
      total: 0,
      tableData: [],
      visible: false,
      dataPO: {
        // isAllDay: "10011001",
        info: true,
        noticeWay: "60671001"
      },
      dateRange: [],
      timeList: [
        {
          label: "全天",
          value: "60701001"
        },
        {
          label: "自定义",
          value: "60701002"
        }
      ],
      contentList: [
        // { value: "60711001", label: "基本信息", select: true },
        { value: "60711002", label: "企业动态", select: false },
        { value: "60711003", label: "招标动态", select: false }
      ],
      timeFlag: false,
      rules: {
        // name: [
        //   { required: true, message: "请输入客户名称", trigger: "blur" }
        // ],
        // isAllInfo: [{ required: true, message: "请选择订阅内容", trigger: "change" }],
        // isAllDay: [
        //   { required: true, message: "请选择推送时间", trigger: "change" }
        // ],
        // info: [{ required: true, message: "请选择通知路径", trigger: "change" }]
      },
      params: {
        pageNum: 1,
        pageSize: 10
      },
      consumerId: "",
      subscribeSettingId: ""
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getInfo();
    this.consumerSubscribe();
  },
  methods: {
    coloring() {
      let colors = ["#90cfb7", "#8ca3cc", "#e8ab6f", "#6daae2", "#c8b7d8"];
      let index = Math.floor(Math.random() * 5);
      return colors[index];
    },
    // 获取用户信息
    getInfo() {
      this.$api.personalCenter
        .getInfo()
        .then(res => {
          this.consumerId = res.data.consumerId;
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取列表
    consumerSubscribe() {
      this.$api.personalCenter
        .consumerSubscribe({ ...this.params })
        .then(res => {
          res.rows.forEach(item => {
            if (!item.logoUrl) {
              this.$api.industry
                .getCompanyName({
                  companyName: item.clientName
                })
                .then(ress => {
                  item.logoImg = ress.msg;
                  // item.bcColor = this.coloring();
                  this.$forceUpdate();
                })
                .catch(msg => {
                  if (msg?.msg) {
                    this.$message.error(msg?.msg);
                  }
                });
            }
          });
          this.tableData = res.rows;
          this.total = res.total;
        });
    },
    // 获取订阅设置
    getSiteSubscribe() {
      this.$api.personalCenter
        .getSiteSubscribe()
        .then(res => {
          this.subscribeSettingId = res.data.subscribeSettingId;
          // this.dateRange = [res.data.pushStartTime, res.data.pushEndTime];
          this.contentList.forEach(item => {
            if (item.value == "60711001") {
              if (res.data.infoBasic == "10011001") {
                item.select = true;
              } else item.select = false;
            } else if (item.value == "60711002") {
              if (res.data.infoDynamic == "10011001") {
                item.select = true;
              } else item.select = false;
            } else if (item.value == "60711003") {
              if (res.data.infoBidding == "10011001") {
                item.select = true;
              } else item.select = false;
            }
            this.$forceUpdate();
            let { contentList } = this;
            let isAll = true;
            contentList.forEach(items => {
              if (!items.select) {
                isAll = false;
                this.$forceUpdate();
              }
            });
            this.$forceUpdate();
            this.dataPO.isAllInfo = isAll;
            this.dataPO.noticeWay = "60671001";
            this.dataPO.info = true;
          });
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.consumerSubscribe();
    },
    // 订阅设置按钮
    handleSet() {
      this.visible = true;
      this.getSiteSubscribe();
    },
    // 跳转详情  路由跳转到前台详情页面
    handleDetail(val, item) {
      let type = val.clientType == "60661001" ? 1 : 2;
      let dynamic = item  ? item : "";
      this.$api.personalCenter
        .isMyClient({ clientId: val.clientId })
        .then(res => {
          // if (res.data == 0) {
          //   let url = this.$router.resolve({
          //     path: `/client/client-detail?id=${val.clientId}&type=${type}&dynamic=${dynamic}`
          //   });
          //   window.open(url.href, "_blank");
          // } else {
            let url = this.$router.resolve({
              path: `/client/consumer-detail?id=${val.clientId}&type=${type}&pathType=1&dynamic=${dynamic}`
            });
            window.open(url.href, "_blank");
          // }
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
      // this.$router.push(`/client/client-detail?id=${val.id}`);
    },
    // 取消订阅按钮
    delSubscribe(row) {
      this.$confirm("确认取消关注吗？", "提示", {
        confirmButtonText: "确认取消",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$api.industry
          .jsubscribeClient({
            clientId: row.clientId
          })
          .then(() => {
            this.$message.success("已取消关注");
            this.consumerSubscribe();
          })
          .catch(msg => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      });
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.form.resetFields();
      this.dataPO = {
        info: true,
        noticeWay: "60671001"
      };
    },
    // 切换推送时间按钮
    handleChangeSendTime(val) {
      if (val == "60701002") {
        this.timeFlag = true;
        this.dataPO.isAllDay = "10011002";
        this.$forceUpdate();
      } else if (val == "60701001") {
        this.timeFlag = false;
        this.dataPO.isAllDay = "10011001";
        this.$forceUpdate();
      }
    },
    // 全选
    handleCheckAllChange() {
      let { contentList } = this;
      if (this.dataPO.isAllInfo) {
        contentList.forEach(item => {
          item.select = true;
          this.handleCheckContent(item);
        });
      } else {
        contentList.forEach(item => {
          item.select = false;
          this.handleCheckContent(item);
        });
      }
      this.contentList = contentList;
    },
    // 单选
    handleCheckContent(val) {
      if (val.value == "60711001") {
        if (val.select) {
          this.dataPO.infoBasic = "10011001";
        } else {
          this.dataPO.infoBasic = "10011002";
        }
      }
      if (val.value == "60711002") {
        if (val.select) {
          this.dataPO.infoDynamic = "10011001";
        } else {
          this.dataPO.infoDynamic = "10011002";
        }
      }
      if (val.value == "60711003") {
        if (val.select) {
          this.dataPO.infoBidding = "10011001";
        } else {
          this.dataPO.infoBidding = "10011002";
        }
      }
      let { contentList } = this;
      let isAll = true;
      contentList.forEach(item => {
        if (!item.select) {
          isAll = false;
        }
      });
      this.dataPO.isAllInfo = isAll;
    },
    // 表单提交事件
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$api.personalCenter
            .siteSubscribe({
              ...this.dataPO,
              consumerId: this.consumerId,
              subscribeSettingId: this.subscribeSettingId
              // pushStartTime: this.dateRange ? this.dateRange[0] : "",
              // pushEndTime: this.dateRange ? this.dateRange[1] : ""
            })
            .then(res => {
              this.visible = false;
              this.$message.success("提交成功");
              this.consumerSubscribe();
            })
            .catch(err => {
              this.$message.error("提交失败");
            });
        } else {
          this.$message.error("提交失败!");
          return false;
        }
      });
    },
    // 表单取消
    cancel() {
      this.visible = false;
      this.resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
