<template>
  <div class="default-modal">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :show-close="closeflag"
      :before-close="close"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :width="width"
      :modal="false"
      custom-class="modal-custom"
      :lock-scroll="true"
    >
      <slot slot="title" name="title"></slot>
      <slot></slot>
      <!-- <slot slot="footer" name="footer"></slot> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // 是否显示弹窗
    visible: {
      type: Boolean,
      default: true
    },
    closeflag: {
      type: Boolean,
      default: true
    },
    // 标题
    title: {
      type: String,
      default: ""
    },
    // 弹窗的宽度
    width: {
      type: String,
      default: "50%"
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.stopScroll();
      } else {
        this.canScroll();
      }
    }
  },
  methods: {
    //禁止滚动
    stopScroll() {
      var mo = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    /***允许滚动***/
    canScroll() {
      var mo = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    close() {
      // 关闭弹窗
      this.$emit("update:visible", false);
      // 重置表单方法
      this.$emit("reset");
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
